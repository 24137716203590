import { mapGetters, mapActions } from 'vuex'
import { prakerjaFaq } from '@/constant'
import { scrollToTheTarget } from '@/utils'
export default {
  name: 'prakerja',
  components: {
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    Redeem: () => import(/* webpackChunkName: "redeem" */ '@/components/forms/Redeem'),
    // RedeemV2: () => import(/* webpackChunkName: "RedeemV2" */ '@/components/forms/RedeemV2'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Search: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Search'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Close'),
    G2Academy: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/G2Academy'),
    Arrow: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/ArrowForward'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/pagination/Pagination')
  },
  data() {
    return {
      isRedeemVisible: false,
      isRegisterPrakerjaVisible: false,
      isLoginModalVisible: false,
      isRegisterModalVisible: false,
      titleModal: 'Masuk untuk melihat profil',
      titleRedeemModal: 'Masukkan kode kelas prakerja',
      type: 'PRAKERJA',
      typeModal: 'reedemCode',
      referralCode: 0,
      syllabusLink: null,
      params: this.$route.params.referralcode,
      prakerjaFaq: prakerjaFaq,
      currentPage: 1,
      totalData: 0,
      searchTerm: '',
      keyword: '',
      prakerjaProcess: [
        {
          title: 'Pendaftaran Kartu Prakerja',
          desc: 'Ikuti proses pendaftaran yang tersedia pada <a href="https://www.prakerja.go.id/" class="font-semibold text-yellow" target="_blank">Website Kartu Prakerja</a>.'
        },
        {
          title: 'Seleksi Kartu Prakerja',
          desc: 'Ikuti test pengetahuan dasar & motivasi. Pilih Batch.<br/>Dapatkan unique ID ketika lolos.'
        },
        {
          title: 'Pilih Pelatihan G2Academy di Platform E-commerce'
        },
        {
          title: 'Bergabung di Group Telegram dan Ikuti Pelatihan Webinar',
          desc: 'Redeem kode di G2Academy. Bergabung di Group Telegram dan mengikuti pelatihan Webinar via Zoom.'
        },
        {
          title: 'Berikan Ulasan & Rating',
          desc: 'Berikan ulasan dan rating kelas di form G2Academy & platform E-commerce.'
        },
        {
          title: 'Dapatkan Insentif dari PMO Prakerja',
          desc: 'Dapatkan insentif Rp 600 ribu/bulan selama 4 bulan setelah menyelesaikan pelatihan.'
        },
        {
          title: 'Isi survey Prakerja',
          desc: 'Diwajibkan untuk mengisi survey yang tersedia di Dashboard Prakerja.'
        }
      ],
      aboutPrakerja:
        'Program Kartu Prakerja adalah program pengembangan kompetensi kerja dan kewirausahaan yang ditujukan untuk pencari kerja, pekerja/buruh yang terkena pemutusan hubungan kerja, dan/atau pekerja/buruh yang membutuhkan peningkatan kompetensi, termasuk pelaku usaha mikro dan kecil. Kami percaya bahwa masyarakat Indonesia sesungguhnya ingin selalu meningkatkan kemampuannya. Program ini didesain sebagai sebuah produk dan dikemas sedemikian rupa agar memberikan nilai bagi pengguna sekaligus memberikan nilai bagi sektor swasta.<br /><br />Jalan digital melalui marketplace dipilih untuk memudahkan pengguna mencari, membandingkan, memilih dan memberi evaluasi. Hanya dengan cara ini, produk bisa terus diperbaiki, tumbuh dan relevan. Menggandeng pelaku usaha swasta, program ini adalah wujud kerjasama pemerintah dan swasta dalam melayani masyarakat dengan semangat gotong royong demi SDM unggul, Indonesia maju.',
      isReadMoreActivated: false
    }
  },
  created() {
    this.getContent()
    this.showRedeemCodeModal()
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('prakerja', ['content', 'tentangPrakerja', 'jaringanKerjaSama', 'kelasRekomendasi', 'isContactUsVisible']),
    videoLink() {
      return 'https://www.youtube.com/embed/wxf9TMQZQlg'
    },
    tentangPrakerja: function () {
      return this.tentangPrakerja
    },
    queryModal() {
      return this.$route.query.modal
    },
    pageSize() {
      return this.isMobile ? 4 : 6
    },
    jaringanKerjasamaItems: function () {
      var array = this.jaringanKerjaSama.jaringanKerjaSamaItemPrakerjas
      var out = []
      var i = 0
      var n = Math.ceil(array.length / 3)
      while (i < n) {
        out.push(array.splice(0, i === n - 1 && array.length > 3 ? array.length : 3))
        i++
      }
      this.jaringanKerjaSama.jaringanKerjaSamaItemPrakerjas = out
      return this.jaringanKerjaSama && this.jaringanKerjaSama.jaringanKerjaSamaItemPrakerjas
    },
    filteredClass() {
      let temp = this.kelasRekomendasi?.kelasRekomendasiItemPrakerjas
      if (this.searchTerm !== '' && this.searchTerm) {
        temp = temp.filter((item) => {
          return item.coreClass.name.toString().toUpperCase().includes(this.searchTerm.toUpperCase())
        })
      }
      return temp
    },
    classList() {
      let i, j
      const temporary = []
      const chunk = this.pageSize
      const data = this.filteredClass
      if (data) {
        for (i = 0, j = data.length; i < j; i += chunk) {
          temporary.push(data.slice(i, i + chunk))
        }
      }
      return temporary
    },
    start() {
      return (this.currentPage - 1) * this.pageSize + 1
    },
    end() {
      const lastOnPage = (this.currentPage - 1) * this.pageSize + this.pageSize
      return lastOnPage > this.totalData ? this.totalData : lastOnPage
    },
    selectedDP() {
      return this.$route.query.dp
    },
    selectedLP() {
      return this.$route.query.lp
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  watch: {
    filteredClass(val) {
      this.totalData = val.length
    }
  },
  methods: {
    ...mapActions(['getUserProfile', 'showLoading', 'hideLoading']),
    ...mapActions('prakerja', ['getPrakerjaContent', 'setContactUsVisible']),
    goTo(target) {
      scrollToTheTarget(target, -120)
    },
    onPageChange(page) {
      this.currentPage = page
    },
    doSearch() {
      this.searchTerm = this.keyword
    },
    clearKeyword() {
      this.keyword = ''
      this.doSearch()
    },
    toggleRedeem() {
      this.isRedeemVisible = !this.isRedeemVisible
    },
    toggleLoginModal() {
      this.isLoginModalVisible = !this.isLoginModalVisible
    },
    toggleRegisterModal() {
      this.isRegisterModalVisible = !this.isRegisterModalVisible
    },
    toggleRegisterPrakerjaModal() {
      this.isRegisterPrakerjaVisible = !this.isRegisterPrakerjaVisible
    },
    getReferralCode(value) {
      this.referralCode = value
      this.toggleRedeem()
    },
    redeemSuccess() {
      this.toggleRegisterPrakerjaModal()
      setTimeout(() => {
        this.$router.push('/thankyou/prakerja')
      }, 500)
    },
    showRedeemCodeModal() {
      if (this.params || this.queryModal) {
        this.toggleRegisterPrakerjaModal()
      }
    },
    isLogin() {
      if (this.isLoggedIn) {
        this.toggleRedeem()
        return
      }
      this.toggleLoginModal()
    },
    getContent: function () {
      this.getPrakerjaContent().then((res) => {
        const total = this.kelasRekomendasi.kelasRekomendasiItemPrakerjas?.length
        this.totalData = total
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.$route.hash && this.$route.hash === '#faq') {
              const offset = this.isMobile ? 500 : -80
              scrollToTheTarget('faq', offset)
            }
            if (this.$route.hash && this.$route.hash === '#panduan') {
              const offset = this.isMobile ? -80 : -100
              scrollToTheTarget('panduan', offset)
            }
          }, 1000)
        })
        this.syllabusLink = res?.tentangPrakerja?.description
      })
    },
    success: function (val) {
      this.$store.dispatch('hideLoading')
    },
    gotoPrakerjaDetail: function (id) {
      // return '/course/geek/partime/' + id
      return 'https://forms.gle/b4W3mWV7sULYpgex5'
    },
    toDetail(slug) {
      this.$router.push(`/class/prakerja/${slug}`)
    },
    downloadSyllabus() {
      window.open(this.syllabusLink)
    },
    toggleContactUsModal() {
      this.setContactUsVisible(!this.isContactUsVisible)
    },
    redirectToLinkG2() {
      window.open('https://wa.me/6285775475917?text=Halo%20Admin%20Prakerja%21%0D%0ASaya%20ingin%20bertanya%20tentang%20...', '_blank')
    },
    redirectToLinkAcc() {
      window.open('https://wa.me/6285736482522?text=Halo%20Admin%20Prakerja%21%0D%0ASaya%20ingin%20bertanya%20tentang%20...', '_blank')
    },
    redirectToLinkBM() {
      window.open('https://wa.me/6283816182375?text=Halo%20Admin%20Prakerja%21%0D%0ASaya%20ingin%20bertanya%20tentang%20...', '_blank')
    },
    redirectToLinkMaha() {
      window.open('https://wa.me/6281250675773?text=Halo%20Admin%20Prakerja%21%0D%0ASaya%20ingin%20bertanya%20tentang%20...', '_blank')
    },
    redirectToLinkTerampil() {
      window.open('https://wa.me/6281286867194?text=Halo%20Admin%20Prakerja%21%0D%0ASaya%20ingin%20bertanya%20tentang%20...', '_blank')
    },
    redirectToLinkPrakerja() {
      window.open('https://bantuan.prakerja.go.id/hc/id/requests/new', '_blank')
    }
  }
}
